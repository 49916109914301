import 'react-popper-tooltip/dist/styles.css';
import '../styles/globals.css';
import { AppProps } from 'next/app';
import Head from 'next/head';

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>MobieTrain ID</title>
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default App;
